/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BET_MARKET_TYPE_GROUP_ENUM } from "@/components/Common/Enums/BetMarketTypeGroupEnum";
import { BetMarketType, IPlayerTypeUpdatedon } from "@/components/Odds/common";
import { americanToDecimal } from "@/utis/bettingFormulas";
import { differenceInHours } from "date-fns";
import { BetMarketSiteBetSiteType } from "./sportsBook";
import { GameDataUpdatedon } from "./gameDataCache";

export type PartialRecord<K extends string | number | symbol, T> = Partial<Record<K, T>>;

type GraphTeamData = {
  gameId: string;
  teamId?: string;
  betValue?: number | null;
  playerId?: number;
  hashCode: number | undefined;
};

type OddsDataMap = {
  hashCode: PartialRecord<string, number>;
  data: PartialRecord<string, PartialRecord<string, number>>;
};

type BetCacheType = {
  betCache?: BetMarketType[];
  betValueRange: Record<string, number[]>;
  odds: PartialRecord<string, PartialRecord<string, OddsDataMap>>;
  gameData: Record<string, GameDataUpdatedon>;
  playerData: Record<number, IPlayerTypeUpdatedon>;
  playerByGames: Record<string, number[]>;
  teamByGames: Record<string, number[]>;
  selectedBetValue: Record<string, number | undefined>;
  selectedBetValueBySportbook: Record<string, Record<string, number | undefined>>;
  draws: Record<string, boolean>;
  selectedSportbooks: BetMarketSiteBetSiteType[];
  searchQuery: string;
  isGraphOpen: boolean;
  graphTeamData?: GraphTeamData;
};

type SetSpreadParams = {
  gameId: string;
  betValue: number;
};

const initialState: BetCacheType = {
  odds: {},
  betValueRange: {},
  gameData: {},
  playerData: {},
  playerByGames: {},
  teamByGames: {},
  selectedBetValue: {},
  selectedBetValueBySportbook: {},
  draws: {},
  selectedSportbooks: [],
  searchQuery: "",
  isGraphOpen: false,
};

export const betCache = createSlice({
  name: "constants",
  initialState,
  reducers: {
    setOdds: (
      state,
      action: PayloadAction<{
        marketTypes: BetMarketType[];
        betGroup?: BET_MARKET_TYPE_GROUP_ENUM;
        marketType?: string;
        selectedSportbooks: BetMarketSiteBetSiteType[];
        allSportbooks: BetMarketSiteBetSiteType[];
      }>,
    ) => {
      const { marketTypes, betGroup, marketType, selectedSportbooks, allSportbooks } = action.payload;
      const sums: Record<string, Record<number, number>> = {};
      const counts: Record<string, Record<number, number>> = {};
      const siteSums: Record<string, Record<string, Record<number, number>>> = {};
      const siteCounts: Record<string, Record<string, Record<number, number>>> = {};
      const isPlayerView = betGroup?.startsWith("PLAYER_PROP");
      const shouldInvert = betGroup?.startsWith("TOTALS") || isPlayerView || betGroup?.includes("TEAM");

      // Don't update data when Graph is open
      if (state.isGraphOpen) return;

      // re calculating odds everytime, should we do it or not?
      // To remove the data when odds is missing or null
      state.odds = {};

      marketTypes.forEach(({ gameId, conditions, listings, hashCode }) => {
        if (conditions.length < 1) return;
        const condition = conditions[0];
        // Check if the query marketType is same as the returned market type
        if (marketType && condition.marketType !== marketType) return;
        // start adding bet value range
        if (isPlayerView && !condition.playerId) {
          return;
        }
        let betKey = isPlayerView ? condition.playerId.toString() : gameId;
        const { betValue } = condition;

        let teamKey = `${condition.teamId ?? ""}`;
        teamKey += condition.overUnder ?? "";
        const betValueKey = `${condition.betValue ?? "DEFAULT"}`;

        if (condition.teamId && condition.overUnder) {
          betKey = `${condition.teamId}`;
        }
        if (!condition.teamId && condition.isTie) {
          teamKey = "DRAW";
        }
        if (condition.playerId) {
          teamKey = `p${condition.playerId}${condition.overUnder || (condition.betValue < 0 ? "UNDER" : "OVER")}`;
        }

        if (!state.odds[gameId]) {
          state.odds[gameId] = {};
        }
        if (!state.odds[gameId]![teamKey]) {
          state.odds[gameId]![teamKey] = {
            hashCode: {},
            data: {},
          };
        }
        if (!state.odds[gameId]![teamKey]?.hashCode[betValueKey]) {
          state.odds[gameId]![teamKey]!.hashCode[betValueKey] = hashCode;
        }

        listings.forEach((listing) => {
          const siteKey = listing.site.id;
          if (!state.odds[gameId]![teamKey]!.data[siteKey]) {
            state.odds[gameId]![teamKey]!.data[siteKey] = {};
          }
          if (state.odds[gameId]![teamKey]!.data[siteKey]![betValueKey] !== listing.americanOdds) {
            state.odds[gameId]![teamKey]!.data[siteKey]![betValueKey] = listing.americanOdds;
          }

          const sportbook = allSportbooks.find((book) => book.id === listing.site.id);
          if (sportbook) {
            const sisterIds = sportbook.sisterSiteIds ?? [];
            sisterIds.forEach((sisterId) => {
              if (!state.odds[gameId]![teamKey]!.data[sisterId]) {
                state.odds[gameId]![teamKey]!.data[sisterId] = {};
              }
              if (state.odds[gameId]![teamKey]!.data[sisterId]![betValueKey] !== listing.americanOdds) {
                state.odds[gameId]![teamKey]!.data[sisterId]![betValueKey] = listing.americanOdds;
              }
            });
          }

          if (selectedSportbooks.find((site) => site.id === listing.site.id)) {
            if (!state.betValueRange[betKey]) {
              state.betValueRange[betKey] = [betValue];
            } else if (!state.betValueRange[betKey].includes(betValue)) {
              state.betValueRange[betKey].push(betValue);
            }
          }
          if (!state.odds[gameId]) {
            state.odds[gameId] = {};
          }
          const game = state.gameData[gameId];
          if (game) {
            const sumValue = !shouldInvert && game.homeTeam.id === condition.teamId ? betValue * -1 : betValue;
            if (!sums[betKey]) {
              sums[betKey] = {};
              counts[betKey] = {};
            }
            if (!sums[betKey][sumValue]) {
              sums[betKey][sumValue] = americanToDecimal(listing.americanOdds);
              counts[betKey][sumValue] = 1;
            } else {
              sums[betKey][sumValue] += americanToDecimal(listing.americanOdds);
              counts[betKey][sumValue] += 1;
            }

            if (!siteSums[siteKey]) {
              siteSums[siteKey] = {};
              siteCounts[siteKey] = {};
            }
            if (!siteSums[siteKey][betKey]) {
              siteSums[siteKey][betKey] = {};
              siteCounts[siteKey][betKey] = {};
            }
            if (!siteSums[siteKey][betKey][sumValue]) {
              siteSums[siteKey][betKey][sumValue] = americanToDecimal(listing.americanOdds);
              siteCounts[siteKey][betKey][sumValue] = 1;
            } else {
              siteSums[siteKey][betKey][sumValue] += americanToDecimal(listing.americanOdds);
              siteCounts[siteKey][betKey][sumValue] += 1;
            }
            // Include sister sites in the calculation
            if (sportbook) {
              const sisterIds = sportbook.sisterSiteIds ?? [];
              sisterIds.forEach((sisterId) => {
                if (!siteSums[sisterId]) {
                  siteSums[sisterId] = {};
                  siteCounts[sisterId] = {};
                }
                if (!siteSums[sisterId][betKey]) {
                  siteSums[sisterId][betKey] = {};
                  siteCounts[sisterId][betKey] = {};
                }
                if (!siteSums[sisterId][betKey][sumValue]) {
                  siteSums[sisterId][betKey][sumValue] = americanToDecimal(listing.americanOdds);
                  siteCounts[sisterId][betKey][sumValue] = 1;
                } else {
                  siteSums[sisterId][betKey][sumValue] += americanToDecimal(listing.americanOdds);
                  siteCounts[sisterId][betKey][sumValue] += 1;
                }
              });
            }
          }
          // end adding bet value range
        });
      });

      Object.keys(sums).forEach((betId) => {
        let bet = 0;
        let maxCount = 0;
        let offset = Infinity;
        let closest = 0;
        const sum = sums[betId];
        const count = counts[betId];

        Object.keys(sum).forEach((betValue) => {
          const avg = sum[+betValue] / count[+betValue];
          if (avg >= -1.77 && avg <= 2.3) {
            if (count[+betValue] >= maxCount) {
              bet = +betValue;
              maxCount = count[+betValue];
            }
          }
          if (Math.abs(avg - 2) < offset) {
            closest = +betValue;
            offset = Math.abs(avg - 2);
          }
        });

        if (bet) {
          if (!state.selectedBetValue[betId]) {
            state.selectedBetValue[betId] = bet;
          }
        } else if (closest) {
          if (!state.selectedBetValue[betId]) {
            state.selectedBetValue[betId] = closest;
          }
        }
      });

      selectedSportbooks.forEach((site) => {
        const siteKey = site.id;
        Object.keys(siteSums[siteKey] || {}).forEach((betId) => {
          if (!state.selectedBetValueBySportbook[betId]) {
            state.selectedBetValueBySportbook[betId] = {};
          }

          let closest = 0;
          let minOffset = Infinity;
          let exactMatchFound = false;

          const sum = siteSums[siteKey][betId];
          const selectedBet = state.selectedBetValue[betId] || 0;
          const numericSelectedBet = +selectedBet;

          Object.keys(sum).forEach((betValue) => {
            const numericBetValue = +betValue;

            if (numericBetValue === numericSelectedBet) {
              state.selectedBetValueBySportbook[betId][siteKey] = numericBetValue;
              exactMatchFound = true;
            } else {
              const offset = Math.abs(numericBetValue - numericSelectedBet);
              if (offset < minOffset) {
                closest = numericBetValue;
                minOffset = offset;
              }
            }
          });

          if (!exactMatchFound) {
            state.selectedBetValueBySportbook[betId][siteKey] = closest;
          }
        });
      });
    },

    setGamesData: (state, action: PayloadAction<GameDataUpdatedon[]>) => {
      const nowDate = Date.now();
      const data = action.payload;
      data.forEach((game) => {
        state.gameData[game.id] = game;
        state.gameData[game.id].lastUpdatedOn = nowDate;
      });
      // Delete the game Data and selectedBetValue Data if the game has not been updated from over an hour
      Object.keys(state.gameData).forEach((gameId) => {
        if (state.gameData[gameId]?.lastUpdatedOn && differenceInHours(nowDate, state.gameData[gameId].lastUpdatedOn!) > 1) {
          delete state.gameData[gameId];
          delete state.selectedBetValue[gameId];
        }
      });
    },
    setPlayerData: (state, action: PayloadAction<IPlayerTypeUpdatedon[]>) => {
      const nowDate = Date.now();
      const data = action.payload;
      data.forEach((player) => {
        state.playerData[player.id] = player;
      });
      // Delete the keys if the Player has not been updated from over an hour
      Object.keys(state.playerData).forEach((key) => {
        const playerId = Number(key);
        if (
          state.playerData[playerId]?.lastUpdatedOn &&
          differenceInHours(nowDate, state.playerData[playerId].lastUpdatedOn!) > 1
        ) {
          delete state.playerData[playerId];
        }
      });
    },

    setSelectedBetValue(state, action: PayloadAction<SetSpreadParams>) {
      const { gameId, betValue } = action.payload;
      state.selectedBetValue[gameId] = betValue;
    },
    setSelectedSportbooks(state, action: PayloadAction<BetMarketSiteBetSiteType[]>) {
      state.selectedSportbooks = action.payload;
    },
    setPlayerByGames(state, action: PayloadAction<Record<string, number[]>>) {
      state.playerByGames = action.payload;
    },
    setTeamByGames(state, action: PayloadAction<Record<string, number[]>>) {
      state.teamByGames = action.payload;
    },
    resetGamesData: (state) => {
      state.gameData = {};
    },
    resetPlayerData: (state) => {
      state.playerData = {};
    },
    resetOddsData: (state) => {
      state.odds = {};
      state.betValueRange = {};
      state.selectedBetValue = {};
      state.selectedBetValueBySportbook = {};
      state.draws = {};
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setGraphOpen: (state, action: PayloadAction<boolean>) => {
      state.isGraphOpen = action.payload;
    },
    setGraphData: (state, action: PayloadAction<GraphTeamData | undefined>) => {
      state.graphTeamData = action.payload;
    },
    setBetCache: (state, action: PayloadAction<BetMarketType[] | undefined>) => {
      state.betCache = action.payload;
    },
  },
});

export const betCacheActions = betCache.actions;

export default betCache.reducer;
