import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { loadState, saveState } from "@/utis/localStorage";
import { useMutation } from "@apollo/client";
import { OddsDisplay } from "@/components/FormattedOdds/common";
import { Box, ButtonBase, Modal, Typography, debounce, styled } from "@mui/material";
import LoginPopup from "@/components/Login/LoginPopup";
import isNil from "@/utis/isNil";
import { CAStateEnum, USStateEnum } from "@/constants/StateEnum";
import CountriesEnum from "@/constants/CountriesEnum";
import useGetPathKey from "@/hooks/useGetPathKey";
import { LoginType } from "@/components/Login/LoginPopup/common";
import { CommonFilterType, getDefaultFilterValuesByPathKey, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { BlockedBetsBetType } from "@/components/BlockedBets/common";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { constantsAction } from "@/store/constants";
import { differenceInHours } from "date-fns";
import { convertUTCToLocalTime } from "@/utis/ApiDateFormats";
import {
  Filters,
  PageFilterProfileType,
  PageFilters,
  SAVE_USER_SETTING,
  SaveUserSetting,
  SaveUserSettingVariables,
  UserSettingsType,
} from "./common";
import { initialState as filterHelperInitialState } from "./filterHelper";
import { DEFAULT_FILTER_VALUE, DEFAULT_NAME, initialState as pageFilterHelperInitialState } from "./pageFilterHelper";
import { useUser } from "../UserContext";
import { UserRestrictionType } from "../UserRestrictions/common";

const KEY = "USER_SETTINGS";

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: inline;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.customBackground.cards};
    border: 1px solid ${theme.palette.warning.light};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(4)};
    height: max-content;
    margin: auto;
    max-width: 1200px;
  `,
);

type UserSettingsContextValue = {
  allPageFilters: PageFilters;
  globalSettings: Filters;
  selectedNavFilter: string;
  isCollapsed: boolean | undefined;
  setSelectedNavFilter: (name: string, showWarning?: boolean) => void;
  setDefaultSportsBookEnums: (payload: string[] | undefined) => void;
  setSportsBookEnums: (payload: string[] | undefined) => void;
  setSportsBookSortId: (payload: number[]) => void;
  setOddsDisplay: (payload: OddsDisplay) => void;
  toggleShowPauseFilter: () => void;
  toggleIsCollapsed: () => void;
  setIsCollapsed: (payload: boolean | undefined) => void;
  setIsCollapsedUserSetting: (payload: boolean) => void;
  setFilterProfileUrl: (payload: { key: KeyOfPages; groupName: string; data: CommonFilterType; filterIcon: string }) => void;
  updateFilterProfileUrl: (payload: { key: KeyOfPages; groupName: string; data: CommonFilterType | null }) => void;
  deleteFilterProfileUrl: (payload: { key: KeyOfPages; groupName: string }) => void;
  setRegion: (payload: USStateEnum | CAStateEnum | undefined) => void;
  setCountry: (payload: CountriesEnum | undefined) => void;
  currPageSettings: PageFilterProfileType | undefined;
  openLoginPopup: (loginType?: LoginType) => void;
  closeLoginPopup: () => void;
  setHideAdvanceFilter: (payload: boolean) => void;
  setHideAdvanceFilterDialog: (payload: boolean) => void;
  deleteAllAdvanceFilter: () => void;
  getCurrentPageBlockedBet: BlockedBetsBetType[] | undefined;
  addBlockedBet: (newBlockedBet: BlockedBetsBetType) => void;
  removeBlockedBet: (newBlockedBetId: number) => void;
};

const UserSettingsContext = createContext<UserSettingsContextValue>(undefined!);

export default UserSettingsContext;

export function useUserSettingsContextSelector<Selected>(selector: (value: UserSettingsContextValue) => Selected) {
  return useContextSelector(UserSettingsContext, selector);
}

function parseUserSettings(userSettings?: string) {
  if (!userSettings) return null;
  try {
    const parseData = JSON.parse(userSettings) as UserSettingsType;
    if (!("pageFilter" in parseData) || !("globalSettings" in parseData)) return null;
    return parseData;
  } catch {
    return null;
  }
}

function getDefaultUserSettingState() {
  const currInitialState: UserSettingsType = loadState<UserSettingsType>(KEY) || {
    globalSettings: filterHelperInitialState,
    pageFilter: pageFilterHelperInitialState,
    blockedBets: undefined,
  };
  return currInitialState;
}

type UserSettingsProviderProps = {
  settings?: string;
  children: React.ReactNode;
};

export function UserSettingsProvider({ settings: userSettingsProps, children }: UserSettingsProviderProps) {
  const { isLoggedIn, restrictions } = useUser();
  const pathKey = useGetPathKey();
  const [selectedNavFilter, setSelectedNavFilter] = useState(DEFAULT_NAME);
  const [openWarningFilter, setOpenWarningFilter] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const [loginPopupType, setLoginPopupType] = useState<LoginType>(LoginType.LOG_IN);
  const dispatch = useAppDispatch();
  const betSites = useAppSelector((state) => state.constantsReducer.betSites);

  const [handleSaveUserSetting] = useMutation<SaveUserSetting, SaveUserSettingVariables>(SAVE_USER_SETTING, {});

  const saveUserSetting = useCallback(
    (settings: UserSettingsType | null) => {
      if (!settings) return;
      if (isLoggedIn) {
        const settingsString = JSON.stringify(settings);
        // If User is  logged in Save setting to User Data
        handleSaveUserSetting({
          variables: {
            settings: settingsString,
          },
        });
      } else {
        // If User is not logged in Save setting to localStorage
        saveState(KEY, settings);
      }
    },
    [handleSaveUserSetting, isLoggedIn],
  );

  const getDefaultSettingsProp = useCallback(() => {
    const defaultProps = getDefaultUserSettingState();
    if (isLoggedIn && userSettingsProps) return parseUserSettings(userSettingsProps) || defaultProps;
    if (isLoggedIn && !userSettingsProps) {
      saveUserSetting(defaultProps);
    }
    if (!isLoggedIn) saveState(KEY, defaultProps);
    return getDefaultUserSettingState();
  }, [isLoggedIn, saveUserSetting, userSettingsProps]);

  const [userSettings, setuserSettings] = useState(getDefaultSettingsProp());
  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(userSettings.globalSettings.isCollapsed);

  // const [fetchUserSetting] = useLazyQuery<FetchUserSetting>(FETCH_USER_SETTING);
  // useEffect(() => {
  //   if(isLoggedIn && isSettingExpired()) {
  //     fetchUserSetting().then((respData) => {
  //       const newSettings = parseUserSettings(respData.data?.user?.user.settings);
  //       if (!newSettings) return;
  //       updateUserSettingTime();
  //       setuserSettings(newSettings);
  //     });
  //   }
  // }, [isLoggedIn, fetchUserSetting, userToken.anonymousToken.token]);

  const saveUserSettingDebounced = useMemo(() => debounce(saveUserSetting, 1000), [saveUserSetting]);

  // This will add the new betSite in the user settings
  useEffect(() => {
    // If user has not selected any sites in global sportsbook, don't add this to selected
    if (!userSettings.globalSettings.sportsBookEnums?.length) return;
    if (!betSites.length) return;
    // as of now there were betSite with last  Id 79, so all next id will be consider as new
    const newBetSites = betSites.filter((bs) => bs.id > 79 && !userSettings.globalSettings.newSportsBookAdded?.[bs.id]);
    if (!newBetSites.length) return;
    setuserSettings((prev) => {
      const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
      if (newState) {
        // Update the User Global Sportsbook with the new sportsbook
        newState.globalSettings.sportsBookEnums?.push(...newBetSites.map((nbs) => nbs.enumValue));
        // Set the newly added sportsbook as true so that it won't add another time
        const newBetSitesMap = newBetSites.reduce<Record<number, boolean>>((acc, curr) => {
          acc[curr.id] = true;
          return acc;
        }, {});
        newState.globalSettings.newSportsBookAdded = { ...newState.globalSettings.newSportsBookAdded, ...newBetSitesMap };
        saveUserSettingDebounced(newState);
      }
      return newState;
    });
  }, [
    betSites,
    saveUserSettingDebounced,
    userSettings.globalSettings.newSportsBookAdded,
    userSettings.globalSettings.sportsBookEnums,
  ]);

  // Cleanup function for Blocked bet if game start time is over 24 hours
  useEffect(() => {
    const currPageBlockedBets = userSettings.blockedBets?.[pathKey];
    if (!currPageBlockedBets?.length) return;
    const removedBlockedBets: number[] = [];
    const currDate = new Date();
    currPageBlockedBets.forEach((bb) => {
      const gameStartTime = convertUTCToLocalTime(bb.gameStartTime);
      if (!gameStartTime) return;
      if (differenceInHours(currDate, gameStartTime) > 24) {
        removedBlockedBets.push(bb.id);
      }
    });
    if (!removedBlockedBets.length) return;
    setuserSettings((prev) => {
      const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
      if (newState && newState.blockedBets) {
        newState.blockedBets[pathKey] = newState.blockedBets[pathKey]?.filter((bets) => !removedBlockedBets.includes(bets.id));
        saveUserSettingDebounced(newState);
      }
      return newState;
    });
  }, []);

  // TODO: Remove this after some time
  // This is used to fix corrupt data. Now the if the advanceFilter is Disabled, we shouldn't have advance filer in any pages stored it in user setting
  // If it is stored than the data might be corrupt due to old logic and enable the advance filter then
  const advFilterExistCheck = useRef(false);
  useEffect(() => {
    if (userSettings && !advFilterExistCheck.current) {
      Object.keys(userSettings.pageFilter).forEach((pageKey) => {
        const anyAdvFilter = userSettings.pageFilter[pageKey as KeyOfPages].some((valFil) => !isNil(valFil.value?.advanceFilter));
        if (anyAdvFilter) {
          setuserSettings((prev) => {
            const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
            if (newState) {
              newState.globalSettings.hideAdvanceFilter = false;
              saveUserSettingDebounced(newState);
            }
            return newState;
          });
        }
      });
      advFilterExistCheck.current = true;
    }
  }, [saveUserSettingDebounced, userSettings]);

  const setDefaultSportsBookEnums = useCallback(
    (payload: string[] | undefined) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState && !newState.globalSettings.sportsBookEnums?.length && payload?.length) {
          newState.globalSettings.sportsBookEnums = payload;
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const setSportsBookEnums = useCallback(
    (payload: string[] | undefined) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState) {
          newState.globalSettings.sportsBookEnums = payload;
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const setSportsBookSortId = useCallback(
    (payload: number[]) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState) {
          newState.globalSettings.sportsBookSortId = payload;
          if (payload) dispatch(constantsAction.sortBetSitesById(payload));
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [dispatch, saveUserSettingDebounced],
  );

  const setOddsDisplay = useCallback(
    (payload: OddsDisplay) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState) {
          newState.globalSettings.oddsDisplay = payload;
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const toggleShowPauseFilter = useCallback(() => {
    setuserSettings((prev) => {
      const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
      if (newState) {
        newState.globalSettings.showPauseFilter = !newState.globalSettings.showPauseFilter;
        saveUserSettingDebounced(newState);
      }
      return newState;
    });
  }, [saveUserSettingDebounced]);

  const setIsCollapsedUserSetting = useCallback(
    (payload: boolean) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState) {
          newState.globalSettings.isCollapsed = payload;
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const toggleIsCollapsed = useCallback(() => {
    setuserSettings((prev) => {
      const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
      if (newState) {
        newState.globalSettings.isCollapsed = !newState.globalSettings.isCollapsed;
        saveUserSettingDebounced(newState);
        setIsCollapsed(newState.globalSettings.isCollapsed);
      }
      return newState;
    });
  }, [saveUserSettingDebounced]);

  const setFilterProfileUrl = useCallback(
    (payload: { key: KeyOfPages; groupName: string; data: CommonFilterType; filterIcon: string }) => {
      const { key, data, groupName, filterIcon } = payload;
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (!newState?.pageFilter) return prev;
        if (!(key in newState.pageFilter)) {
          newState.pageFilter[key] = DEFAULT_FILTER_VALUE;
        }
        newState.pageFilter[key].push({
          name: groupName,
          value: data || "",
          filterIcon,
        });
        saveUserSettingDebounced(newState);
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const updateFilterProfileUrl = useCallback(
    (payload: { key: KeyOfPages; groupName: string; data: CommonFilterType | null }) => {
      const { key, data, groupName } = payload;
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (!newState?.pageFilter) return prev;
        if (!(key in newState.pageFilter)) {
          newState.pageFilter[key] = DEFAULT_FILTER_VALUE;
        }
        const currentGroupIndex = newState.pageFilter[key].findIndex((val) => val.name === groupName);
        if (currentGroupIndex >= 0) {
          newState.pageFilter[key][currentGroupIndex].value = data;
        }
        saveUserSettingDebounced(newState);
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  // TODO: Remove this after some time
  // For some old users, the filter state default doesn't have the updated default value
  useEffect(() => {
    if (userSettings.pageFilter && userSettings.pageFilter.game) {
      const defaultGameFilter = userSettings.pageFilter.game.find((val) => val.name === DEFAULT_NAME);
      if (defaultGameFilter?.value && defaultGameFilter.value.avgColumn === undefined) {
        const defaultFilterValue = getDefaultFilterValuesByPathKey("game");
        const defaultGameFilterState = { ...defaultGameFilter?.value };
        Object.keys(defaultFilterValue).forEach((key) => {
          if (!(key in defaultGameFilterState)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore ES lint not able to know that the that they are of the same types
            defaultGameFilterState[key as keyof SportsBookTableFilterState] = defaultFilterValue[key as keyof CommonFilterType];
          }
        });
        updateFilterProfileUrl({ key: "game", groupName: DEFAULT_NAME, data: defaultGameFilterState });
      }
    }
  }, [updateFilterProfileUrl, userSettings.pageFilter]);

  useEffect(() => {
    if (userSettings.globalSettings.sportsBookSortId) {
      dispatch(constantsAction.sortBetSitesById(userSettings.globalSettings.sportsBookSortId));
    }
  }, [userSettings.globalSettings.sportsBookSortId, dispatch]);

  const deleteFilterProfileUrl = useCallback(
    (payload: { key: KeyOfPages; groupName: string }) => {
      const { key, groupName } = payload;
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (!newState?.pageFilter) return prev;
        const newList = newState.pageFilter[key].filter((val) => val.name !== groupName);
        newState.pageFilter[key] = newList;
        saveUserSettingDebounced(newState);
        if (selectedNavFilter === groupName) {
          setSelectedNavFilter(newList[0].name);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced, selectedNavFilter],
  );

  const setRegion = useCallback(
    (payload: USStateEnum | CAStateEnum | undefined) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState) {
          if (!newState.globalSettings.location) {
            newState.globalSettings.location = {
              region: undefined,
              country: undefined,
            };
          }
          newState.globalSettings.location.region = payload;
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const setCountry = useCallback(
    (payload: CountriesEnum | undefined) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState) {
          if (!newState.globalSettings.location) {
            newState.globalSettings.location = {
              region: undefined,
              country: undefined,
            };
          }
          newState.globalSettings.location.country = payload;
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const setHideAdvanceFilter = useCallback(
    (payload: boolean) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState) {
          newState.globalSettings.hideAdvanceFilter = payload;
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const setHideAdvanceFilterDialog = useCallback(
    (payload: boolean) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (newState) {
          newState.globalSettings.hideAdvanceFilterDialog = payload;
          saveUserSettingDebounced(newState);
        }
        return newState;
      });
    },
    [saveUserSettingDebounced],
  );

  const currPageSettings = useMemo(
    () => userSettings.pageFilter[pathKey]?.find((filter) => filter.name === selectedNavFilter),
    [pathKey, selectedNavFilter, userSettings.pageFilter],
  );

  const isCurrFilterHasRestriction = useCallback((userRestrictions: UserRestrictionType, currSetting: CommonFilterType) => {
    if (!isNil(userRestrictions.rOILimit) && currSetting?.maxRoi && currSetting?.maxRoi > userRestrictions.rOILimit!) {
      return true;
    }
    if (!isNil(userRestrictions.maxResult) && currSetting?.take && currSetting?.take > userRestrictions.maxResult!) {
      return true;
    }
    if (
      userRestrictions.leagueLimit &&
      currSetting?.leagues?.length &&
      currSetting?.leagues?.length > userRestrictions.leagueLimit
    ) {
      return true;
    }
    if (
      userRestrictions.sportbookLimit &&
      currSetting?.sportsBook?.length &&
      currSetting?.sportsBook?.length > userRestrictions.sportbookLimit
    ) {
      return true;
    }
    return false;
  }, []);

  const selectNavFilter = useCallback(
    (name: string, showWarning?: boolean) => {
      setSelectedNavFilter(name);
      const setting = userSettings.pageFilter[pathKey]?.find((filter) => filter.name === name)?.value;
      if (setting && isCurrFilterHasRestriction(restrictions, setting)) {
        setOpenWarningFilter(true);
      }
    },
    [isCurrFilterHasRestriction, pathKey, restrictions, userSettings.pageFilter, setOpenWarningFilter],
  );

  const deleteAllAdvanceFilter = useCallback(() => {
    setuserSettings((prev) => {
      const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
      Object.keys(newState.pageFilter).forEach((pageKey) => {
        newState.pageFilter[pageKey as KeyOfPages].map((valFil) => {
          const newValFil = valFil;
          if (newValFil.value) newValFil.value.advanceFilter = undefined;
          return newValFil;
        });
      });
      saveUserSettingDebounced(newState);
      return newState;
    });
  }, [saveUserSettingDebounced]);

  const addBlockedBet = useCallback(
    (newBlockedBet: BlockedBetsBetType) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        if (!newState.blockedBets) newState.blockedBets = {};
        if (!(pathKey in newState.blockedBets)) newState.blockedBets[pathKey] = [];
        newState.blockedBets[pathKey]!.push(newBlockedBet);
        saveUserSettingDebounced(newState);
        return newState;
      });
    },
    [pathKey, saveUserSettingDebounced],
  );

  const removeBlockedBet = useCallback(
    (newBlockedBetId: number) => {
      setuserSettings((prev) => {
        const newState = JSON.parse(JSON.stringify(prev)) as UserSettingsType;
        newState.blockedBets![pathKey] = newState.blockedBets![pathKey]?.filter((val) => val.id !== newBlockedBetId);
        saveUserSettingDebounced(newState);
        return newState;
      });
    },
    [pathKey, saveUserSettingDebounced],
  );

  const getCurrentPageBlockedBet = useMemo(() => userSettings.blockedBets?.[pathKey], [pathKey, userSettings.blockedBets]);

  const openLoginPopup = useCallback((loginType?: LoginType) => {
    setShowLoginPopup(true);
    setLoginPopupType(loginType || LoginType.LOG_IN);
  }, []);

  const closeLoginPopup = useCallback(() => {
    setShowLoginPopup(false);
  }, []);

  const providerValue: UserSettingsContextValue = useMemo(
    () => ({
      allPageFilters: userSettings.pageFilter,
      globalSettings: userSettings.globalSettings,
      // This is same as the query state from Filter.
      // This is creaated to use the query state before the Filter Provider
      currPageSettings,
      selectedNavFilter,
      isCollapsed,
      setSelectedNavFilter: selectNavFilter,
      setDefaultSportsBookEnums,
      setSportsBookEnums,
      setSportsBookSortId,
      setOddsDisplay,
      toggleShowPauseFilter,
      toggleIsCollapsed,
      setIsCollapsed,
      setIsCollapsedUserSetting,
      setFilterProfileUrl,
      updateFilterProfileUrl,
      deleteFilterProfileUrl,
      setRegion,
      setCountry,
      openLoginPopup,
      closeLoginPopup,
      setHideAdvanceFilter,
      setHideAdvanceFilterDialog,
      deleteAllAdvanceFilter,
      getCurrentPageBlockedBet,
      addBlockedBet,
      removeBlockedBet,
    }),
    [
      userSettings,
      currPageSettings,
      selectedNavFilter,
      isCollapsed,
      selectNavFilter,
      setDefaultSportsBookEnums,
      deleteFilterProfileUrl,
      setFilterProfileUrl,
      setOddsDisplay,
      setSportsBookEnums,
      setSportsBookSortId,
      toggleShowPauseFilter,
      toggleIsCollapsed,
      setIsCollapsed,
      setIsCollapsedUserSetting,
      updateFilterProfileUrl,
      setRegion,
      setCountry,
      openLoginPopup,
      closeLoginPopup,
      setHideAdvanceFilter,
      setHideAdvanceFilterDialog,
      deleteAllAdvanceFilter,
      getCurrentPageBlockedBet,
      addBlockedBet,
      removeBlockedBet,
    ],
  );

  const handleCloseWarningFilter = () => {
    setOpenWarningFilter(false);
  };

  return (
    <>
      <UserSettingsContext.Provider value={providerValue}>{children}</UserSettingsContext.Provider>
      <Modal open={openWarningFilter} onClose={handleCloseWarningFilter} sx={{ display: "flex" }}>
        <StyledBox>
          Oops! It seems like the values you&apos;ve selected for your current filter exceed your limitations. To continue
          refining your search and remove those restrictions, please
          <ButtonBase
            onClick={() => openLoginPopup()}
            sx={{
              ":hover": { textDecoration: "underline" },
              mx: "4px",
              mb: "4px",
            }}
          >
            <Typography fontWeight="bold" sx={{ textDecoration: "underline" }}>
              Login / Signup in to your account.
            </Typography>
          </ButtonBase>
        </StyledBox>
      </Modal>
      <LoginPopup open={showLoginPopup} onClose={closeLoginPopup} loginType={loginPopupType} />
    </>
  );
}
