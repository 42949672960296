import { Box, Stack, Typography, filledInputClasses, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import VerificationInput from "react-verification-input";
import { gql, useMutation } from "@apollo/client";
import PageSpinner from "@/components/PageSpinner";
import { updateUserRefreshTokens } from "@/user/common";
import ErrorBoundaryPopup from "@/components/ErrorBoundary/ErrorBoundaryPopup";
import SucessPopup from "@/components/SucessPopup";
import { useTranslations } from "next-intl";

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($hashCode: String!, $code: String!) {
    user {
      emailVerification(hashCode: $hashCode, code: $code) {
        userId
        accessToken
        refreshToken
        isNewUser
      }
    }
  }
`;

type VerifyEmail = {
  user: {
    emailVerification: {
      userId: string;
      accessToken: string;
      refreshToken: string;
      isNewUser: boolean;
    };
  };
};

type VerifyEmailVariables = {
  hashCode: string;
  code: string;
};

export const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.text.primary};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
    height: max-content;
    margin: auto;
    position: relative;
    width: 425px;

    ${theme.breakpoints.down("md")} {
      max-width: 425px;
      width: calc(100% - ${theme.spacing(3)});
      padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    }
  `,
);

type LoginVerificationCodeProps = {
  hashCode: string;
  emailAddress: string;
  onClose: () => void;
  setShowPlansModal: (val: boolean) => void;
};

function LoginVericationCode(props: LoginVerificationCodeProps) {
  const { emailAddress, hashCode, onClose: onParentModalClose, setShowPlansModal } = props;
  const [error, setError] = useState<Error | null>(null);
  const [sucess, setSucess] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>();
  const timerRef = useRef<NodeJS.Timeout>();

  const [handleEmailVerification, { loading, reset }] = useMutation<VerifyEmail, VerifyEmailVariables>(VERIFY_EMAIL, {
    onError: (err) => {
      reset();
      setVerificationCode(undefined);
      setError(err);
    },
    onCompleted: async (resData) => {
      const { refreshToken, userId, isNewUser: newUserFlag } = resData.user.emailVerification;
      if (refreshToken && userId) {
        updateUserRefreshTokens({
          refreshToken: { token: refreshToken },
          userId,
        });
        setSucess(true);
        if (newUserFlag) {
          onParentModalClose?.();
          setShowPlansModal(true);
        } else {
          onParentModalClose?.();
          timerRef.current = setTimeout(() => window.location.reload(), 2000);
        }
      }
    },
  });

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    [],
  );

  const onInputComplete = (code: string) => {
    handleEmailVerification({
      variables: {
        hashCode,
        code: code.toUpperCase(),
      },
    });
  };

  const onCloseErrorPopup = () => {
    setError(null);
    reset();
    setVerificationCode(undefined);
  };

  const t = useTranslations("verification");

  return (
    <Stack direction="column" pb={3} mt={2}>
      <Typography variant="header">{t("verification")}</Typography>
      <Typography variant="body2" color="text.secondary" mb={3} mt={2}>
        {t("verification_email_sent", { emailAddress })}
      </Typography>
      {loading ? (
        <PageSpinner text="" height="auto" />
      ) : (
        <VerificationInput
          value={verificationCode}
          onComplete={onInputComplete}
          length={6}
          autoFocus
          onChange={(ev) => setVerificationCode(ev.toUpperCase())}
          classNames={{
            container: filledInputClasses.input,
          }}
          containerProps={{ style: { alignSelf: "center" } }}
        />
      )}
      {error && <ErrorBoundaryPopup message={error.message} onClose={onCloseErrorPopup} />}
      {sucess && (
        <SucessPopup
          message={
            <>
              <b>{t("success_message_title")}</b>
              {t("success_message_desc")}
            </>
          }
        />
      )}
    </Stack>
  );
}

export default LoginVericationCode;
