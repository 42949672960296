import { PaletteType } from "@/theme/common";
import { Box, CircularProgress, useTheme } from "@mui/material";
import Image from "next/image";
import React, { ComponentProps } from "react";

type LogoProgressSpinnerProps = ComponentProps<typeof CircularProgress>;

function LogoProgressSpinner({ size = "70px", ...restProps }: LogoProgressSpinnerProps) {
  const theme = useTheme();

  return (
    <Box width={size} height={size} sx={{ m: 1, position: "relative" }}>
      <Image
        src={theme.palette.mode === PaletteType.Dark ? `/P-logo-circle-dark.png` : `/P-logo-circle-light.png`}
        alt="Pick The Odds"
        layout="fill" // This will make the image fill the parent element
        objectFit="contain" // or 'cover' depending on how you want the image to behave
        style={{ padding: "10%" }}
      />
      <CircularProgress
        size={size}
        thickness={2}
        {...restProps}
        sx={{
          position: "absolute",
          zIndex: 1,
          color: "text.primary",
          animationDuration: '2s',
          ...restProps.sx,
        }}
        {...restProps}
      />
    </Box>
  );
}

export default LogoProgressSpinner;
